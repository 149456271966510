import localFont from 'next/font/local'

const vazir = localFont({
  src: [
    {
      path: './../../public/fonts/Vazir-Black-FD.woff2',
      weight: '900',
    },
    {
      path: './../../public/fonts/Vazir-Bold-FD.woff2',
      weight: '600',
    },
    {
      path: './../../public/fonts/VazirFD.woff2',
    },
    {
      path: './../../public/fonts/Vazir-Light-FD.woff2',
      weight: '300',
    },
    {
      path: './../../public/fonts/Vazir-Medium-FD.woff2',
      weight: '500',
    },
    {
      path: './../../public/fonts/Vazir-Thin-FD.woff2',
      weight: '100',
    },
  ]
})

export default vazir


/* [
  {
    path: './../../public/fonts/Vazir-Black-FD.woff2',
    weight: '900',
  },
  {
    path: './../../public/fonts/Vazir-Bold-FD.woff2',
    weight: '600',
  },
  {
    path: ',
  },
  {
    path: './../../public/fonts/Vazir-Light-FD.woff2',
    weight: '300',
  },
  {
    path: './../../public/fonts/Vazir-Medium-FD.woff2',
    weight: '500',
  },
  {
    path: './../../public/fonts/Vazir-Thin-FD.woff2',
    weight: '100',
  },
] */