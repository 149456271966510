
import MainContentLayout from "../../../customLayout/mainContentLayout";
import AdsBannerImages from "./AdsBannerImages";
import AdsSlider from "./AdsSlider";

export type SlidersProps = {
  slidersData: {
    "data": {
      "landingPage": {
        "rightSliders": {
          image?: string | null | undefined;
          link?: string | null | undefined;
      }[],
        "leftSliders": {
          image?: string | null | undefined;
          link?: string | null | undefined;
      }[]
      }
    }
  }
}


// eslint-disable-next-line import/no-default-export
export default function AdsSection(props:SlidersProps ) {

  return (
    <MainContentLayout 
      className="mt-48 max-992:mt-16 1170:max-w-1170 992:max-w-970 mx-auto md:h-[40vw] max-h-[460px] max-md:mt-12 max-md:!max-w-full h-auto"
    >
      <div className="flex flex-row  justify-between gap-2.5 w-full  h-auto md:h-[40vw]">
        <AdsBannerImages className="md:w-[43vw] md:grid grid-rows-2 gap-2.5 w-full h-[40v] max-h-[460px] grid max-md:hidden" images={props?.slidersData?.data?.landingPage?.rightSliders} />
        <AdsSlider className="w-full md:w-[56vw] h-full max-h-[460px] max-md:h-auto rounded-2xl overflow-hidden max-md:m-4 max-md:mx-2" images={props?.slidersData?.data?.landingPage?.leftSliders} />
      </div>
    </MainContentLayout>
  )
}