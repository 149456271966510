
import { i18n } from '@lingui/core';
import { Snackbar } from '@mui/material'
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import Image from 'next/image'
import IconCross from '../../icons/IconCross'
// eslint-disable-next-line import/no-cycle
import { useCompareMessage } from './CompareMessageProvider'



export function AddProductsToCompareSnackbar() {
  const { displayMessageBar, setDisplayMessageBar, product } = useCompareMessage();

  const handleHide = () => {
    setDisplayMessageBar(false)
  }
  return (
    <>
      {displayMessageBar && (
        <Snackbar
        className='fixed'
          open={displayMessageBar}
          autoHideDuration={6000}
          onClose={handleHide}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          sx={{zIndex:'9999999999'}}
        >
          <div className='flex relative max-w-[350px] justify-between items-center box-border  rounded-sm border border-solid bg-white gap-3 p-3'>
            <Image width={60} height={60} alt=''
              src={product?.image_url ?? ''} className='border border-solid w-16 h-16' />
            <p className='text-base font-medium text-light-400'>{product?.name} {i18n._('Added to comparison list.')}</p>

            <IconCross width={24} height={24} className='text-light-400 absolute top-1 left-1 cursor-pointer' onClick={handleHide} />
          </div>
        </Snackbar>
      )}
    </>
  )
}
