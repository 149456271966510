import { useQuery } from '@graphcommerce/graphql'
import { ExtendableComponent, useNumberFormat } from '@graphcommerce/next-ui'
import { useThemeProps } from '@mui/material'
import { useMemo } from 'react'

/* eslint-disable */
import * as Types from '@graphcommerce/graphql-mesh/.mesh'

export type MoneyFragment = { currency?: Types.CurrencyEnum | null; value?: number | null }

/* eslint-disable */

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
import { cn } from '../../lib/utils'

export const StoreConfigDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'StoreConfig' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'storeConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'website_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'store_code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'store_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locale' } },
                { kind: 'Field', name: { kind: 'Name', value: 'base_currency_code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'default_display_currency_code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title_suffix' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title_prefix' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title_separator' } },
                { kind: 'Field', name: { kind: 'Name', value: 'default_title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cms_home_page' } },
                { kind: 'Field', name: { kind: 'Name', value: 'catalog_default_sort_by' } },
                { kind: 'Field', name: { kind: 'Name', value: 'category_url_suffix' } },
                { kind: 'Field', name: { kind: 'Name', value: 'product_url_suffix' } },
                { kind: 'Field', name: { kind: 'Name', value: 'secure_base_link_url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'secure_base_url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'root_category_uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'weight_unit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'product_reviews_enabled' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allow_guests_to_write_product_reviews' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'grid_per_page' } },
                { kind: 'Field', name: { kind: 'Name', value: 'list_per_page' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autocomplete_on_storefront' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minimum_password_length' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'required_character_classes_number' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'magento_wishlist_general_is_enabled' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StoreConfigQuery, StoreConfigQueryVariables>
export type StoreConfigQueryVariables = Types.Exact<{ [key: string]: never }>

export type StoreConfigQuery = {
  storeConfig?: {
    website_name?: string | null
    store_code?: string | null
    store_name?: string | null
    locale?: string | null
    base_currency_code?: string | null
    default_display_currency_code?: string | null
    title_suffix?: string | null
    title_prefix?: string | null
    title_separator?: string | null
    default_title?: string | null
    cms_home_page?: string | null
    catalog_default_sort_by?: string | null
    category_url_suffix?: string | null
    product_url_suffix?: string | null
    secure_base_link_url?: string | null
    secure_base_url?: string | null
    root_category_uid?: string | null
    weight_unit?: string | null
    product_reviews_enabled?: string | null
    allow_guests_to_write_product_reviews?: string | null
    grid_per_page?: number | null
    list_per_page?: number | null
    autocomplete_on_storefront?: boolean | null
    minimum_password_length?: string | null
    required_character_classes_number?: string | null
    magento_wishlist_general_is_enabled?: string | null
  } | null
}

type OverridableProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  round?: boolean
  /** @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#parameters */
  // eslint-disable-next-line react/no-unused-prop-types
  formatOptions?: Intl.NumberFormatOptions
}

export type MoneyProps = {
  showCurrency?: boolean
  currencyClassName?: string
  className?: string
  Rials?: boolean
} & MoneyFragment &
  OverridableProps

const name = 'Money'

/** Expose the component to be exendable in your theme.components */
declare module '@mui/material/styles/components' {
  interface Components {
    Money?: Pick<ExtendableComponent<OverridableProps>, 'defaultProps'>
  }
}

export function Money(props: MoneyProps) {
  const { currency, value, round = false, formatOptions } = useThemeProps({ name, props })

  const { currencyClassName = '', className = '', showCurrency = true, Rials = false } = props

  const { data: config } = useQuery(StoreConfigDocument)

  const digits = (value ?? 0) % 1 !== 0

  const options: Intl.NumberFormatOptions = useMemo(
    () => ({
      style: 'currency',
      currency: currency ?? config?.storeConfig?.base_currency_code ?? '',
      ...(round && !digits && { minimumFractionDigits: 0 }),
      ...(round && digits && { minimumFractionDigits: 2 }),
      ...(!round && { minimumFractionDigits: 2 }),
      ...formatOptions,
    }),
    [config?.storeConfig?.base_currency_code, currency, digits, formatOptions, round],
  )
  const numberFormatter = useNumberFormat(options)

  if (typeof value === 'undefined' || value === null) return null

  if (numberFormatter.format(value).split('ل').length > 1 && Rials)
    return (
      <div className={`flex flex-row w-max ${className}`}>
        <span>
          {
            numberFormatter
              .format(value * 10)
              .split('٫')[0]
              .split('ل')[1]
          }
        </span>{' '}
        {showCurrency ? <span className={currencyClassName && currencyClassName}>ریال</span> : ''}
      </div>
    )
  if (numberFormatter.format(value).split('IRR').length > 1 && Rials)
    return (
      <div className={`${className}`}>
        <span>
          {
            numberFormatter
              .format(value * 10)
              .split('.')[0]
              .split('IRR')[1]
          }
        </span>{' '}
        {showCurrency ? <span className={currencyClassName && currencyClassName}>ریال</span> : ''}
      </div>
    )
  if (numberFormatter.format(value).split('ل').length > 1)
    return (
      <p className={cn('text-inherit', className)}>
        {numberFormatter.format(value).split('٫')[0].split('ل')[1]}
        {showCurrency ? <> تومان</> : null}
      </p>
    )
  if (numberFormatter.format(value).split('IRR').length > 1)
    return (
      <p className={cn('text-inherit', className)}>
        {numberFormatter.format(value).split('.')[0].split('IRR')[1]}
        {showCurrency ? <> تومان</> : null}
      </p>
    )

  return <>{numberFormatter.format(value)}</>
}
