import { useEffect } from 'react'
import loadGoftinoWidget from './widgets/goftino/loadGoftinoWidget'

const GoftinoWidget = () => {
  useEffect(() => {
    loadGoftinoWidget()
  }, [])
  return null
}

export default GoftinoWidget
