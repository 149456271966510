import { ApolloCartErrorSnackbar, useFormGqlMutationCart } from '@graphcommerce/magento-cart'
import { CartItemFragment } from '@graphcommerce/magento-cart-items'
import { Trans } from '@lingui/react'
import { LoadingButton } from '@mui/lab'
import { ButtonProps, SxProps, Theme, styled } from '@mui/material'
import type { DistributedOmit } from 'type-fest'
import { RemoveItemFromCartDocument } from '../../graphql/cart/removeItemFromCart.gql'

export type RemoveItemFromCartProps = DistributedOmit<CartItemFragment, '__typename'> & {
  sx?: SxProps<Theme>
  buttonProps?: Omit<ButtonProps, 'type' | 'loading'>
  children?: React.ReactNode
}

const Form = styled('form')({})

export function RemoveItemFromCart(props: RemoveItemFromCartProps) {
  const { uid, quantity, prices, buttonProps, ...formProps } = props
  const form = useFormGqlMutationCart(RemoveItemFromCartDocument, { defaultValues: { uid } })
  const { handleSubmit, formState, error } = form
  const submitHandler = handleSubmit(() => {})
  return (
    <Form noValidate onSubmit={submitHandler} {...formProps}>
      <LoadingButton
        variant='text'
        className='hover:bg-transparent bg-transparent'
        {...buttonProps}
        sx={{fontSize:'14px'}}
        type='submit'
        color='error'
        loading={formState.isSubmitting}
      >
        {props?.children ?? <Trans id='Remove' />}
      </LoadingButton>
      <ApolloCartErrorSnackbar error={error} />
    </Form>
  )
}
