import { Navigation, Pagination, EffectFade } from 'swiper/modules'

import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css/effect-fade';
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css'
import { ProductWidgetsType } from '../../../../api/landingPage/widgets';
import styles from './style.module.css';
import Link from 'next/link'
import Image from 'next/image';
import { Money } from '@graphcommerce/magento-store';



type DiscountSelectionType = {
  mostDiscountedWidget: ProductWidgetsType[]
}
export default function SalesSlider(props: DiscountSelectionType) {
  const { mostDiscountedWidget } = props;
  return (
    <div className={`${styles['sales-slider']} max-md:w-full flex-1 h-full overflow-hidden shadow-sm hover:shadow-md transition-shadow border border-solid max-992:w-[283px] border-light-50`}>
      <Swiper
        modules={[Navigation, Pagination, EffectFade]}
        navigation
        loop
        slidesPerView={1}
        spaceBetween={60}
        effect="fade"
        className='h-full w-full'
      >

        {mostDiscountedWidget?.map((product) => (
          <SwiperSlide key={product?.id}>
            <div className="bg-white h-full relative  flex flex-col items-center p-2">
              <Link href={`/p/${product?.url_key}`} className='flex flex-1 justify-between items-center'>
                <Image className='max-md:!size-52' width={300} height={300} src={product?.image?.url} alt="" />
              </Link>



              <div className="w-full flex justify-between items-start pb-3 mt-3">

                <Link href={`/p/${product?.url_key}`} className="text-sm text-center text-default-900 transition-colors hover:text-primary-500">
                  {product.name}
                </Link>

                <div className='flex flex-col items-end'>

                  <span className="text-sm text-secondary-300 font-semibold">
                    <Money {...product?.price_range?.minimum_price?.final_price} />
                  </span>
                  <span className="text-sm text-light-400 line-through">
                    {product?.price_range?.minimum_price?.discount?.percent_off > 0 && <Money {...product?.price_range?.minimum_price?.regular_price} />}
                  </span>
                </div>
              </div>
              {product?.price_range?.minimum_price?.discount?.percent_off > 0 &&
                <span className="w-12 h-12 bg-error-500 absolute left-2 top-2 text-center text-base text-white flex items-center justify-center rounded-full rounded-br-none">
                  %{Math.round(product?.price_range?.minimum_price?.discount?.percent_off)}
                </span>}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}