import { Trans } from "@lingui/react";
import Image from "next/image";
import Link from "next/link";
import CertificatesShow from "../../CertificatesShow";

import SocialMediaList from "../../socialMediaLinks";
import HeaderLogo from "../HeaderLogo";
import { storeLogoQuery } from "../../../graphql/storeLogo.gql";



type props = storeLogoQuery

// eslint-disable-next-line import/no-default-export
export default function BottomFooterSection(props:props) {
  return (
    <div className="max-w-970 1170:max-w-1170 mx-auto flex justify-between items-center my-9">
      <div className="flex flex-1">
        <div className="relative min-w-[207px] h-[157px]">
          <Image src="/om-ftr-about.jpg" fill className="object-cover" alt="about" />
        </div>

        <div className="pr-5 flex flex-col">
          <div className="-mr-2">
            <HeaderLogo storeLogo={props.storeLogo} white/>

          </div>

          <div>
            <p className="text-sm w-[240px] xl:w-[425px]"><Trans id="Ordami is a specialized authority in the field of beauty and health that tries to increase your knowledge and understanding of all the points and considerations related to the selection, purchase and consumption of cosmetics and health products; So that you can feel their positive effect as much as possible."/></p>
            <div className="flex justify-end mt-2">

              <Link href='/pages/about' className="text-sm px-2 shadow-2xl bg-default-900 text-light-200 transition-colors hover:text-default-100 hover:bg-light-200">
                <Trans id="read more" />
              </Link>
            </div>
          </div>
          <SocialMediaList />
        </div>
      </div>
      <div className="flex flex-1 justify-end">
        <CertificatesShow />
      </div>

    </div>
  )
}