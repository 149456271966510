/* eslint-disable */
import * as Types from '@graphcommerce/graphql-mesh/.mesh';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

export const getAdsBannerDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getAdsBanner"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"topNavStaticMessages"}}]}}]} as unknown as DocumentNode<getAdsBannerQuery, getAdsBannerQueryVariables>;
export type getAdsBannerQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type getAdsBannerQuery = { topNavStaticMessages?: Array<string | null> | null };
