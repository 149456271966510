import axios from 'axios'
const getToken = () => {
  const stringData =
    typeof window !== 'undefined' ? window?.localStorage?.getItem('apollo-cache-persist') : null
  if (stringData !== null) {
    const data = JSON.parse(stringData)
    return `bearer ${data?.CustomerToken?.token}`
  }
  return ''
}
const baseServerUrl = process.env.NEXT_PUBLIC_BACKEND_URL


const instance = axios.create({
  baseURL: baseServerUrl,
  timeout: 20000,
  headers: {
    'content-Type': 'application/json',
    Accept: 'application/json',
    // Authorization: getToken(),
  },
})

// eslint-disable-next-line import/no-default-export
export default instance
