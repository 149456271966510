import { useQuery } from '@graphcommerce/graphql'
import { Image } from '@graphcommerce/image'
import { CategorySearchDocument } from '@graphcommerce/magento-search'
import { Trans } from '@lingui/react'
import Link from 'next/link'
import React, { useEffect, useRef, useState } from 'react'
import IconSearch from '../icons/IconSearch'
import Loading from '../icons/Loading'
import IconClose from '@mui/icons-material/Close'
import { useRouter } from 'next/router'
import { ProductListSearchDocument } from '../../graphql/productList/ProductListSearch.gql'
import { suggestionsDocument, suggestionsQuery } from '../../graphql/suggestions/suggestions.gql'

// eslint-disable-next-line import/no-default-export
export default React.forwardRef(
  (
    { open, setOpen }: { open: boolean; setOpen: React.Dispatch<React.SetStateAction<boolean>> },
    ref,
  ) => {
    const [text, setText] = useState<string>('')
    const [loading, setLoading] = useState(false)
    // const [searchTerm, setSearchTerm] = useState<any>(null)
    const [show, setShow] = useState(false)
    const { refetch: getProducts } = useQuery(ProductListSearchDocument, {
      fetchPolicy: 'network-only',
      skip: text?.length < 3,
    })
    const { refetch: getCategores } = useQuery(CategorySearchDocument, {
      fetchPolicy: 'network-only',
      skip: text?.length < 3,
    })
    const { refetch: getWords } = useQuery(suggestionsDocument, {
      fetchPolicy: 'network-only',
      skip: text?.length < 3,
    })
    const [pData, setPData] = useState<any>(undefined)
    const [cData, setCData] = useState<any>(undefined)
    const [wData, setWData] = useState<suggestionsQuery>()

    const handleSearch = async (search: string) => {
      setLoading(() => true)
      // setSearchTerm(search)
      await Promise.all([
        getProducts({
          search,
          pageSize: 5,
          currentPage: 1,
        }),
        getCategores({
          pageSize: 10,
          search,
        }),
        getWords({
          search,
        }),
      ]).then((res) => {
        setPData(() => res[0].data.products?.items)
        setCData(() => res[1].data.categories?.items)
        setWData(() => res[2].data)
        setLoading(false)
        setShow(true)
      })
    }

    const router = useRouter()
    const handleClose = () => {
      setShow(false)
      setOpen(false)
    }
    function navigateToSearch() {
      if (text.length > 2) {
        setOpen(false)
        router.push(`/search/${text}`)
      }
    }
    useEffect(() => {
      const timer = setTimeout(async () => {
        if (text?.length > 2) await handleSearch(text)
      }, 2000)
      return () => clearTimeout(timer)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [text])
    function handleKeyDown(event) {
      if (event.key === 'Enter') {
        navigateToSearch()
      }
    }
    const inputRef = useRef<HTMLInputElement>(null)
    useEffect(() => {
      if (inputRef.current) {
        inputRef.current.focus()
      }
    }, [open])

    const clearInput = () => setText('')
    return (
      <div className='flex-1 max-w-[95%] md:max-w-2xl relative  m-auto'>
        <div className='relative outline outline-[1px] outline-[#e5e5e5] rounded-sm'>
          <button
            onClick={clearInput}
            type='button'
            className='absolute right-0 top-0 w-10 h-10 border-none outline-0 shadow-none flex justify-center items-center scale-[calc(1.05)] md:scale-100 text-ordme-gray-400/80 992:hidden'
          >
            <IconClose width={17} height={24} />
          </button>
          <input
            onKeyDown={handleKeyDown}
            value={text}
            className='w-full h-10 border-0 outline-0 shadow-none text-sm font-medium px-3 max-992:pr-10 text-ordme-gray-400 placeholder:text-ordme-gray-400/70'
            onChange={(e) => setText(e.target.value)}
            placeholder='جست‌و‌جو ...'
            ref={inputRef}
          />
          <button
            type='button'
            onClick={navigateToSearch}
            className='max-992:hidden absolute left-0 top-0 w-10 h-10 border-none outline-0 shadow-none flex justify-center items-center bg-primary-500 scale-[calc(1.05)] md:scale-100 md:bg-light-150 text-white md:text-light-400 hover:bg-zinc-300 transition duration-300'
          >
            <IconSearch width={17} height={24} />
          </button>
        </div>

        {loading && (
          <span className='absolute inline-block left-11 top-0.5'>
            <Loading width={36} height={36} className='text-primary-500' />
          </span>
        )}

        {show && open && (
          <div
            ref={ref as any}
            onClick={(e) => e.stopPropagation()}
            className='absolute flex justify-between items-stretch right-0 left-0 mt-1 bg-white border border-solid shadow-sm rounded-sm z-[9999]'
          >
            <div className='w-full md:w-[64%]'>
              {pData &&
                pData?.map((item) => (
                  <div
                    className='flex justify-between px-3 py-2 cursor-pointer hover:bg-light-80'
                    key={item?.uid}
                  >
                    <div className='border border-solid w-[60px] h-[60px]'>
                      <Image
                        width={60}
                        height={60}
                        alt=''
                        src={item?.small_image?.url ?? 'noimage.jpg'}
                      />
                    </div>
                    <Link
                      onClick={handleClose}
                      href={`/p/${item?.url_key}`}
                      className='flex-1 flex flex-col justify-between pr-3'
                    >
                      <div className='text-sm contents font-normal text-default-100'>
                        {item?.name}
                        <span className='text-secondary-300 text-sm font-medium'>
                          <Trans id='Available' />
                        </span>
                      </div>
                    </Link>
                  </div>
                ))}
              {pData && pData?.length > 0 ? (
                <div className='flex justify-end mt-3 p-3'>
                  <span
                    onClick={navigateToSearch}
                    className='text-sm cursor-pointer px-6 py-2 font-medium text-white bg-primary-500 hover:bg-primary-700 shadow-md'
                  >
                    <Trans id='All items' />
                  </span>
                </div>
              ) : (
                <p>موردی یافت نشد</p>
              )}
            </div>
            <div className='w-[1px] bg-light-170' />
            <div className='flex-col hidden md:flex w-[35%] p-3 justify-start items-stretch'>
              <div>
                <p className='text-sm font-medium text-light-400 border-b border-solid border-light-170'>
                  <Trans id='Categories' />
                </p>
                <div>
                  {cData &&
                    cData?.map((item) => (
                      <Link
                        onClick={handleClose}
                        href={item?.url_path ?? '#'}
                        key={item?.uid}
                        className='text-sx block py-1'
                      >
                        {item?.name}
                      </Link>
                    ))}
                </div>
              </div>
              <div className='mt-4'>
                <p className='text-sm font-medium text-light-400 border-b border-solid border-light-170'>
                  <Trans id='Suggested words' />
                </p>
                <div>
                  {wData?.products?.suggestions &&
                    wData?.products?.suggestions.slice(0, 3).map((item) => (
                      <Link
                        onClick={handleClose}
                        href={`/search/${item?.term}`}
                        key={item?.term}
                        className='text-xs block py-1'
                      >
                        {item?.term}
                      </Link>
                    ))}
                </div>
              </div>
              {/* <div className="mt-4">
              <div className="flex justify-between items-center border-b border-solid border-light-170 ">
                <p className="text-sm font-medium text-light-400 ">
                  <Trans id="Related articles" />
                </p>
                <Link onClick={handleClose} href="#" className="text-sm font-medium text-default-900">
                  <Trans id="All items" />
                </Link>
              </div>
              <div>
                {[...Array(3).keys()].map((item) => (
                  <Link onClick={handleClose} href="#" key={item} className="text-xs block py-1 w-[97%] text-overflow-custom">
                    {i18n._('It makes the brush to be easily and steadily in the hand')}{item + 1}
                  </Link>
                ))}
              </div>
            </div> */}
            </div>
          </div>
        )}
      </div>
    )
  },
)
