import Image from "next/image";
import Link from "next/link";

export type AdsBannerImagesType = {
  images?:  ({
    image?: string | null | undefined;
    link?: string | null | undefined;
} | null)[] | null | undefined
  className?: string;
}
// eslint-disable-next-line import/no-default-export
export default function AdsBannerImages(props: AdsBannerImagesType) {

  const { images, className } = props;
  return (
    <div className={`${className}  `}>
      <div className="grid grid-cols-2 gap-2.5 overflow-hidden rounded-2xl ">
        {images && images[0] && <Link href={images[0].link??'#'} className="row-span-1 col-span-1 w-full">
          <Image width={300} height={300} alt="" src={images[0].image??'#'} className="rounded-2xl h-full w-full  overflow-hidden" />
        </Link>}

        {images && images[1] && <Link href={images[1].link??'#'} className="row-span-1 col-span-1  w-full">
          <Image width={300} height={300} alt="" src={images[1].image??'#'} className="rounded-2xl overflow-hidden h-full w-full" />
        </Link>}
      </div>

      <div className="flex justify-between">
        {images && images[2] && <Link href={images[2].link??'#'} className="w-full h-full rounded-2xl overflow-hidden">
          <Image width={300} height={300} alt="" src={images[2].image??'#'} className="h-full w-full" />
        </Link>}
      </div>
    </div>
  )
}