import { useCartQuery } from '@graphcommerce/magento-cart'
import { i18n } from '@lingui/core'
import { useContext, useState, useEffect } from 'react'
import { ModalContext } from '../../../context/context'
import IconCart3 from '../../icons/IconCart3'
// eslint-disable-next-line import/no-cycle
import CartPage from '../../showCart'
import { getCartDocument } from '../../../graphql/checkout/getCart.gql'

// eslint-disable-next-line import/no-default-export
export default function CartIcon() {
  const useModal = useContext(ModalContext)
  const cart = useCartQuery(getCartDocument, { fetchPolicy: 'network-only' })
  const { data } = cart

  const [isMobileView, setIsMobileView] = useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const checkMobileView = () => setIsMobileView(window.innerWidth < 992)
      checkMobileView()
      window.addEventListener('resize', checkMobileView)
      return () => window.removeEventListener('resize', checkMobileView)
    }
  }, [])

  return (
    <div
      className='relative'
      onClick={() =>
        useModal.set &&
        useModal.set({
          title: i18n._('Cart'),
          content: <CartPage />,
          open: true,
          position: 'right',
        })
      }
    >
      <IconCart3 width={30} height={29} className='text-light-400 cursor-pointer' />

      {data?.cart?.items && data?.cart?.items?.length > 0 ? (
          <span className={`absolute text-sm leading-[1.2] w-4 h-4 text-center rounded-full text-light-100 ${isMobileView? 'bottom-[5px] right-0 transform translate-x-1/2 translate-y-1/2 bg-white text-ordme-Green border border-ordme-Green': '-top-2 -left-2 bg-ordme-Green'}`}>
          {data?.cart?.total_quantity}
        </span>
      ) : (
        <span className={`absolute text-sm leading-[1.2] w-4 h-4 text-center rounded-full text-light-100 ${isMobileView? 'bottom-[5px] right-0 transform translate-x-1/2 translate-y-1/2 bg-white text-ordme-Green border border-ordme-Green': '-top-2 -left-2 bg-ordme-Green'}`}>
          0
        </span>
      )}
    </div>
  )
}
