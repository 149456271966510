import { Trans } from '@lingui/react'
import Image from 'next/image'
import Link from 'next/link'
import IconPhone from '../../icons/IconPhone'
import CertificatesShow from '../../CertificatesShow'

const menuItems = [
  {
    title: <Trans id='About Ordme' />,
    href: '/pages/about',
  },
  {
    title: <Trans id='Contact Ordme' />,
    href: '/pages/contact',
  },
  {
    title: <Trans id='Frequently asked questions' />,
    href: '/pages/faq',
  },
  {
    title: <Trans id='Discount guide' />,
    href: '/pages/coupon',
  },
]
// eslint-disable-next-line import/no-default-export
export default function MobileFooter() {
  return (
    <div className='flex flex-col pb-5 pt-1.5 md:hidden'>
      <div className='flex items-center justify-between sm:justify-center [&>*]:p-3 px-5'>
        <CertificatesShow/>
      </div>

        <ul className='flex justify-center flex-wrap gap-y-5 mb-6'>
          {menuItems.map((item) => (
            <li className='px-3 max-sm:w-[50%] max-sm:text-center' key={item.title}>
              <Link href={item.href ?? '#'} className='text-xs text-light-200'>
                {item.title}
              </Link>
            </li>
          ))}
        </ul>

      <div className='text-center text-xs text-light-200 flex justify-center gap-3 overflow-hidden'>
        <p><Trans id='Answering hours: non-holiday days, from 8 to 24'/></p>
        <Link href='#' className='flex gap-0.5 items-center'>
          <IconPhone width={18} height={18} />
          ۰۲۱۹۱۳۰۰۳۱۲
        </Link>
      </div>
    </div>
  )
}
