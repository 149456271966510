import { PageOptions } from '@graphcommerce/framer-next-pages'

import { PageMeta, StoreConfigDocument } from '@graphcommerce/magento-store'
import {
  allWidgetsData,
  getWidgetsData,
  slidersWidgets,
  widgetsDataType,
} from '../api/landingPage/widgets'
import { DevLayoutNavigation, DevLayoutNavigationProps } from '../components'
import { LayoutDocument } from '../components/Layout/Layout.gql'
import AdsSection, { SlidersProps } from '../components/Routes/Home/AdsSection'

import BlogSection from '../components/Routes/Home/BlogSection'
import HomeWidgets from '../components/Routes/Home/HomeWidgets'
import OrdermeOffers from '../components/Routes/Home/OrdemeOffers'
import Sales from '../components/Routes/Home/Sales'
import { DefaultPageDocument, DefaultPageQuery } from '../graphql/DefaultPage.gql'
import { MegaMenuContentDocument, MegaMenuContentQuery } from '../graphql/MegaMenuContent.gql'
import { storeLogoDocument, storeLogoQueryVariables } from '../graphql/storeLogo.gql'
import { graphqlSharedClient, graphqlSsrClient } from '../lib/graphql/graphqlSsrClient'
import AdsBannerImages from '../components/Routes/Home/AdsSection/AdsBannerImages'
import IndexSchema from '../components/schema/IndexSchema'
import { GetStaticProps, MetaRobots } from '@graphcommerce/next-ui'
import { GetCmsPageDocument, GetCmsPageQuery } from '../graphql/GetCmsPage.gql'
import { decodeHtmlEntities } from '../lib/decodeHtmlEntities'

type Props = GetCmsPageQuery & {
  locale: string | undefined
  widgetsData: widgetsDataType
  sliders: SlidersProps
} & MegaMenuContentQuery &
  storeLogoQueryVariables

type RouteProps = { url: string }
type GetPageStaticProps = GetStaticProps<DevLayoutNavigationProps, Props, RouteProps>

export type HotAndNewCardProps = {
  image?: string
  video?: string
  description: string
  title: string
  url: string
  classes?: {
    wrapper: string
  }
  theme?: 'dark' | 'light'
}

function CmsPage(props: Props) {
  const { widgetsData, sliders, locale, cmsPage : page } = props

  return (
    <>
      <PageMeta
        title={page?.meta_title ?? ''}
        metaDescription={page?.meta_description ?? ''}
        metaRobots={["index","follow"]}
        canonical='/'
        ogImage={"/logo.png"}
      />

      <IndexSchema />
      <h1 className='sr-only'>اُردمی مرجع تخصصی محصولات آرایشی، بهداشتی و تندرستی</h1>
      <div className='max-sm:[&>*]:px-4'>
        <div className='[&>*]:1170:!max-w-1170 [&>*]:!max-w-970 [&>*]:!mx-auto'>
          <AdsSection slidersData={sliders} />
        </div>

        <div className='max-sm:[&>*]:px-0 [&>*]:1170:!max-w-1170 [&>*]:!max-w-970 [&>*]:!mx-auto'>
          <Sales
            mostDiscountedWidget={widgetsData?.data?.mostDiscountedWidget}
            verticalFeatured={sliders?.data?.landingPage?.verticalFeatured}
          />
        </div>

        <AdsBannerImages
          className='flex flex-col space-y-0 md:hidden h-auto gap-[10px] w-auto m-auto 992:mx-2 mb-7'
          images={sliders?.data?.landingPage?.rightSliders}
        />

        <OrdermeOffers />

        <HomeWidgets widgetsData={widgetsData} />

        {locale === "fa" ? <BlogSection /> : null}
      </div>
    </>
  )
}

CmsPage.pageOptions = {
  Layout: DevLayoutNavigation,
} as PageOptions

export default CmsPage

export const getStaticProps: GetPageStaticProps = async ({ locale }) => {
  const client = graphqlSharedClient(locale)
  const staticClient = graphqlSsrClient(locale)
  const conf = client.query({ query: StoreConfigDocument })
  const page = staticClient.query({ query: GetCmsPageDocument, variables: { id : 2 } })
  const layout = staticClient.query({ query: LayoutDocument, fetchPolicy: 'cache-first' })
  const menuContent = staticClient.query({ query: MegaMenuContentDocument })
  const storeLogo = staticClient.query({ query: storeLogoDocument })
  console.log("NEXT_PUBLIC_BASE_URLNEXT_PUBLIC_BASE_URLNEXT_PUBLIC_BASE_URL", process.env.NEXT_PUBLIC_BASE_URL)
  
  return {
    props: {
      ...(await page)?.data,
      ...(await layout)?.data,
      ...(await menuContent)?.data,
      ...(await storeLogo)?.data,
      locale,
      widgetsData: await getWidgetsData(allWidgetsData),
      sliders: await getWidgetsData(slidersWidgets("")),
      apolloState: await conf.then(() => client.cache.extract()),
    },
    revalidate : 60 * 20
  }
}
